.border-none:before {
  display: none;
}
.border-none:after {
  display: none;
}
.flex-fix {
  -ms-flex: 1 1 0px;
  -webkit-box-flex: 1;
  -webkit-flex: 1;
  flex: 1;
  -webkit-flex-basis: 0px;
  flex-basis: 0px;
  width: 1%;
}
.hide-scrollbar::-webkit-scrollbar {
  width: 0;
  height: 0;
}
.allcover {
  position: absolute;
  top: 0;
  right: 0;
}
.center {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}
.ct {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
}
.cl {
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
}
.clear-fix {
  *zoom: 1;
}
.clear-fix:before,
.clear-fix:after {
  content: "";
  display: table;
  clear: both;
  overflow: hidden;
}
.order-collect {
  box-sizing: border-box;
  padding-bottom: 1rem;
}
.order-collect .order-address {
  background: #fff;
  position: relative;
  padding: 0.36rem 0.3rem;
  box-sizing: border-box;
  line-height: 0.4rem;
}
.order-collect .order-address .user-info {
  font-size: 0.4rem;
  color: #333;
  margin-bottom: 3px;
}
.order-collect .order-address .user-info .user-name {
  margin-right: 0.26rem;
}
.order-collect .order-address .user-address {
  font-size: 0.22rem;
  color: #666666;
}
.order-collect .order-address .order-line {
  height: 3px;
  width: 100%;
  background: url("~@/assets/img_dingdan_tiaoxiushi@2x.png") no-repeat;
  background-size: 100%;
  position: absolute;
  bottom: 0;
  left: 0;
}
.order-collect .order-content {
  padding: 0.2rem 0.3rem;
  box-sizing: border-box;
  background: #fbfbfb;
}
.order-collect .order-content .goods-shop {
  padding: 0.2rem;
  box-sizing: border-box;
  font-size: 0.28rem;
  color: #333;
  position: relative;
}
.order-collect .order-content .goods-shop::before {
  content: "";
  width: 2px;
  height: 0.2rem;
  background: var(--main-color);
  position: absolute;
  top: 50%;
  margin-top: -0.1rem;
  left: 0;
}
.order-collect .order-content .order-info {
  padding: 0 0.2rem;
  box-sizing: border-box;
  background: #fff;
  margin-bottom: 0.3rem;
}
.order-collect .order-content .order-info .info-title {
  font-size: 0.3rem;
  color: #333;
  padding: 0.2rem 0;
  border-bottom: 1px solid #e1e1e1;
  margin-bottom: 0.2rem;
}
.order-collect .order-content .order-info .info-item {
  display: flex;
  align-items: center;
  justify-content: space-between;
  font-size: 0.28rem;
  color: #333;
  margin-bottom: 0.2rem;
}
.order-collect .order-content .order-info .info-all {
  display: flex;
  align-items: center;
  flex-direction: row-reverse;
  font-size: 0.28rem;
  color: #333;
  height: 1rem;
  border-top: 1px solid #e1e1e1;
}
.order-collect .order-content .order-info .info-all p {
  margin-left: 5px;
}
.order-collect .order-content .order-info .order-line {
  border-bottom: 1px solid #e1e1e1;
  height: 1rem;
  display: flex;
  align-items: center;
  justify-content: space-between;
  font-size: 0.28rem;
  color: #333;
}
.order-collect .order-content .order-info .order-line:nth-last-child(1) {
  border: none;
}
.order-collect .order-btn {
  width: 100%;
  height: 1rem;
  background: #fff;
  position: fixed;
  bottom: 0;
  padding-right: 0.3rem;
  box-sizing: border-box;
  box-shadow: 0px 2px 8px 0px rgba(0, 0, 0, 0.1);
}
.fs-weight {
  font-weight: bold;
}
.explode-text {
  text-align: right;
  line-height: 0.4rem;
  font-size: 0.28rem;
}
.color-main {
  color: var(--main-color);
}
